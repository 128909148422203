<template>
  <!--
    Displays the videoDirectorConfig and makes it editable.
  -->
  <Portlet
    :title="$t('videoDirectorConfigFormComponent.videoDirectorConfigEdit')"
    class="videoDirectorConfigForm"
    icon="video"
  >
    <div slot="buttons">
      <font-awesome-icon
        v-if="showReloadButton"
        v-tooltip="$t('configReload.reloadVideoDirectorConfig')"
        :class="['alt-pointer color-primary mt-1', { 'fa-spin' : reloadLoading}]"
        icon="sync-alt"
        style="vertical-align: middle;"
        @click="reloadConfig()"
      />
    </div>
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template v-if="configData">
        <Portlet
          title="VideoRecorder"
          icon="video"
          class="videoRecorderPortlet"
        >
          <div slot="buttons">
            <button
              class="btn btn-sm btn-primary float-right" 
              @click="addVideoRecorderConfig"
            >
              <font-awesome-icon
                class="mr-2"
                icon="plus"
              />
              <span>{{ $t('add') }}</span>
            </button>
          </div>
          <div class="mr-3">
            <table :class="['table kendo-table table-kendo mb-3', { 'is-invalid': this.$validator.errors.has('VideoRecorderConfigs') }]">
              <colgroup>
                <col>
                <col>
                <col>
                <col width="30">
                <col width="30">
                <col width="190">
              </colgroup>
              <thead class="thead">
                <tr>
                  <th>{{ $t('camera') }}</th>
                  <th>{{ $t('location') }}</th>
                  <th>{{ $t('active') }}</th>
                  <th>ActiveOnStart</th>
                  <th>{{ $t('preview') }}</th>
                  <th>{{ $t('options') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(config, videoRecorderIndex) in configData.videoRecorderConfigs"
                  :key="`VideoDirectorConfigForm-table-row-${ videoRecorderIndex }`"
                >
                  <td> {{ getCameraInfo(config.cameraId) }}</td>
                  <td> {{ getCameraLocation(config) }}</td>
                  <td>
                    <toggle-button
                      v-model="config.active"
                      :width="32"
                      :height="17"
                    />
                  </td>
                  <td>
                    <div class="form-check">
                      <input
                        v-if="!config.previewCamera"
                        v-model="config.activeOnStart"
                        disabled
                        readonly
                        type="checkbox"
                        class="form-check-input"
                      >
                    </div>
                  </td>
                  <td>
                    <div class="form-check">
                      <input
                        v-model="config.previewCamera"
                        disabled
                        readonly
                        type="checkbox"
                        class="form-check-input"
                      >
                    </div>
                  </td>
                  <td>
                    <div class="btn-group btn-group-sm">
                      <template v-if="!(config.previewCamera || !config.active)">
                        <router-link
                          :to="getConfigureUrl(config)"
                          class="btn btn-sm btn-primary"
                        >
                          <font-awesome-icon
                            class="mr-2"
                            icon="cog"
                            style="vertical-align: middle;"
                          />{{ $t('configure') }}
                        </router-link>
                      </template>
                      <template v-else>
                        <router-link
                          tag="button"
                          :disabled="true"
                          :to="getConfigureUrl(config)"
                          class="btn btn-sm btn-primary"
                        >
                          <font-awesome-icon
                            class="mr-2"
                            icon="cog"
                            style="vertical-align: middle;"
                          />{{ $t('configure') }}
                        </router-link>
                      </template>
                      <button
                        class="btn btn-primary btn-sm"
                        @click="showSidebarEdit(config)"
                      >
                        <font-awesome-icon
                          class="mr-2"
                          icon="edit"
                        />
                        <span>{{ $t('edit') }}</span>
                      </button>
                      <button
                        class="btn btn-sm btn-secondary"
                        @click="openConfirmDeleteModal(config.id)"
                      >
                        <font-awesome-icon
                          class="mr-2 gray"
                          icon="trash"
                        />
                        <span>{{ $t('remove') }}</span>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <span
              v-show="errors.has('VideoRecorderConfigs')"
              class="badge badge-danger"
            >{{ errors.first('VideoRecorderConfigs') }}</span>
          </div>
        </Portlet>

        <Portlet
          title="Common"
          icon="cog"
          class="commponPortlet mt-2"
        >
          <div class="ml-3">
            <hr class="m-0 p-0 mb-4 pb-1">
            <div class="form-group row mb-2">
              <label class="col-12 col-sm-3 col-form-label">{{ $t('finishDelay') }}</label>
              <div class="col-12 col-sm-9">
                <div class="input-group">
                  <input
                    v-model="configData.finishDelay"
                    :class="['form-control', { 'is-invalid': this.$validator.errors.has('FinishDelay') }]"
                    type="number"
                  >
                  <div class="input-group-append">
                    <span class="input-group-text">{{ $t('seconds') }}</span>
                  </div>
                </div>
              </div>
            </div>
            <span
              v-show="errors.has('FinishDelay')"
              class="badge badge-danger"
            >{{ errors.first('FinishDelay') }}</span>

            <div class="form-group row mb-2">
              <label class="col-12 col-sm-3 col-form-label">{{ $t('finishOnLastRelease') }}</label>
              <div class="col-12 col-sm-9">
                <toggle-button
                  v-model="configData.finishOnLastRelease"
                  :labels="{ checked: $t('active'), unchecked: $t('inactive') }"
                  :class="['m-0 ml-4 mt-2', { 'is-invalid': this.$validator.errors.has('FinishOnLastRelease') }]"
                  :width="90"
                  :height="30"
                  :font-size="12"
                />
              </div>
            </div>
            <span
              v-show="errors.has('FinishOnLastRelease')"
              class="badge badge-danger"
            >{{ errors.first('FinishOnLastRelease') }}</span>
          </div>
        </Portlet>
      </template>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
      <hr>
      <router-link
        :to="`/installation/${ installationId }/tracker`"
        class="btn btn-secondary float-left"
      >
        <font-awesome-icon
          class="mr-1"
          icon="caret-left"
        />
        <span>
          {{ $t('back') }}
        </span>
      </router-link>
      <button
        v-if="updated"
        class="btn btn-primary float-right"
        @click="setConfig()"
      >
        <font-awesome-icon
          class="mr-1"
          icon="upload"
        />
        <span>
          {{ $t('save') }}
        </span>
      </button>
      <div
        v-if="updated"
        class="red float-right mr-4"
      >
        <h3><span class="badge badge-danger">Unsaved changes detected</span></h3>
      </div>
      <div class="clearfix" />
    </template>
    <SweetModal
      ref="confirmDelete"
      :title="$t('videoDirectorConfigFormComponent.deleteVideoRecorder')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p>{{ $t('videoDirectorConfigFormComponent.sureToDeleteVideoRecorder') }}</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2 gray"
          icon="times"
        />
        <span>{{ $t('cancel') }}</span>
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeVideoRecorderConfig()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />
        <span>{{ $t('delete') }}</span>
      </button>
      <div class="clearfix" />
    </SweetModal>
    <Sidebar
      v-if="showSidebarBoolean"
      :show-sidebar="showSidebarBoolean"
      @close="hideSidebar"
    >
      <AddVideoRecorderConfiguration
        v-if="addingElement"
        :installation-id="installationId"
        :lane-number="laneNumber"
        @reloadConfig="reloadConfigAndCloseSidebar()"
        @setReloadButton="setReloadButton"
        @hide="hideSidebar"
      />
      <EditVideoRecorderConfiguration
        v-else
        :configuration="configuration"
        :installation-id="installationId"
        :lane-number="laneNumber"
        @reloadConfig="reloadConfigAndCloseSidebar()"
        @setReloadButton="setReloadButton"
        @hide="hideSidebar"
      />
    </Sidebar>
  </Portlet>
</template>

<script>
// import components
import { SweetModal } from 'sweet-modal-vue';

// import mixins
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: 'VideoDirectorConfiguration',
  components: {
    SweetModal,
    Sidebar: () => import('@/components/Base/Sidebar.vue'),
    AddVideoRecorderConfiguration : () => import('@/components/Config/VideoDirector/AddVideoRecorderConfiguration.vue'),
    EditVideoRecorderConfiguration : () => import('@/components/Config/VideoDirector/EditVideoRecorderConfiguration.vue')
  },
  mixins: [
    errorMixin,
    dateTimeMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      loading: true,
      configData: null,
      componentName: "videodirector",
      deleteIndex: null,
      cameraConfigData: null,
      showSidebarBoolean: false,
      addingElement: false,
      configuration: null,
      cameras: [],
      originalConfigData: null,
      showReloadButton: false,
      reloadLoading: false
    }
  },
  computed: {
    updated () {
      return JSON.stringify(this.configData) !== JSON.stringify(this.originalConfigData);
    }
  },
  async created () {
    await this.getAllCameras();
    this.getConfig();
  },
  methods: {
        //#region Sidebar
    showSidebarEdit (config) {
      this.addingElement = false;
      this.setCurrentConfig(config);
      this.showSidebar();
    },
    setCurrentConfig (config) {
      this.configuration = Object.assign({}, config); //clones Object without reference to fix close sidebar problem
    },
    showSidebar () {
      this.showSidebarBoolean = true;
    },
    hideSidebar () {
      this.showSidebarBoolean = false;
    },
    addVideoRecorderConfig () {
      this.addingElement = true;
      this.showSidebar();
    },
    setReloadButton (value) {
      this.showReloadButton = value;
    },
    //#endregion
    getCameraInfo (cameraId) {
      let cameraInfo = `(CameraId: ${ cameraId })`;
      let camera = this.cameras.find(cam => cam.id === cameraId);
      if(this.cameras.length === 0 || !camera)
      {
         return cameraInfo;
      }
      return camera.cameraType + ' - ' + camera.ipAddress + ' ' + cameraInfo;
    },
    getCameraLocation (config) {
      let num = Number(config.orderId);
      if(config.previewCamera) {
        return "Starthaus Camera " + (num ? num - 100 : 0);
      } else {
        return "Camera " + num;
      }
    },
    getConfigureUrl (config) {
      if (!this.laneNumber)
      {
        return `/installation/${ this.installationId }/tracker/config/videodirector/${ config.id }`;
      }
      else
      {
        return `/installation/${ this.installationId }/tracker/config/videodirector/lane/${ this.laneNumber }/recorder/${ config.id }`;
      }    
    },
    openConfirmDeleteModal (index) {
      this.deleteIndex = index;
      this.$refs.confirmDelete.open();
    },
     reloadConfigAndCloseSidebar () {
        this.hideSidebar();
        this.getConfig();
    },
    removeVideoRecorderConfig () {
      let url = `/Config/RemoveVideoRecorder?installationId=${ this.installationId }&videoRecorderId=${ this.deleteIndex }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.delete(url)
        .then((response) => {
          if (response && response.status == 204) {
            this.setReloadButton(true);
            this.$snotify.error(this.$t('configReload.failedReload'));
          } else {
            this.setReloadButton(false);
          }
          this.$snotify.success(this.$t('videoDirectorConfigFormComponent.videoRecorderWasRemoved'));
        })
        .catch(() => {
          this.$snotify.danger(this.$t('videoDirectorConfigFormComponent.videoRecorderWasNotRemoved'));
        })
        .finally(() => {
          this.getConfig();
          this.deleteIndex = null;
          this.$refs.confirmDelete.close();
        });
    },
    setConfig () {
      let url = `/Config/UpdateVideoDirectorConfig?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.post(url, this.configData)
        .then((response) => {
          if (response && response.status == 204) {
            this.setReloadButton(true);
            this.$snotify.error(this.$t('configReload.failedReload'));
          } else {
            this.setReloadButton(false);
          }
          this.$snotify.success(this.$t('videoDirectorConfigFormComponent.configWasSaved'));
          this.originalConfigData = JSON.parse(JSON.stringify(this.configData))
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    },
    getConfig () {
      let url = `/Config/GetVideoDirectorConfig?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }    
          this.configData = response.data;
          this.originalConfigData = JSON.parse(JSON.stringify(this.configData));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getAllCameras () {
      let url = `/CameraManagement/GetAllCameras?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      } 
      await this.axios.get(url)
        .then((response) => {
          if (!response || !response.data) {
            return;
          }
          this.cameras = response.data; 
        })
        .catch(() => {
          this.loading = false;
        });
    },
    updateConfig (config) {
       let params = {
        videoRecorderConfig: config,
        comment: null
      }
      let url = `/Config/UpdateVideoRecorderConfig?installationId=${ this.installationId }&videoRecorderId=${ config.id}`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      } 
      this.axios.post(url, params)
        .then((response) => {
          if (response && response.status == 204) {
            this.setReloadButton(true);
            this.$snotify.error(this.$t('configReload.failedReload'));
          } else {
            this.setReloadButton(false);
          }
          this.$snotify.success(this.$t('videoDirectorConfigFormComponent.configWasSaved'));
        })
        .catch((error) => {
          this.$snotify.error(this.$t('videoDirectorConfigRecorderFormComponent.configNotSaved'));
          this.error_clear();
          this.error_validate(error);
        });
    },
    reloadConfig () {
      this.reloadLoading = true;
      let url = `/Config/ReloadVideoDirectorConfig?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.post(url)
        .then((response) => {
          if (response && response.status == 204) {
            this.setReloadButton(true);
            this.$snotify.error(this.$t('configReload.failedReload'));
          } else {
            this.setReloadButton(false);
            this.getConfig();
          }
        })
        .finally(() => {
          this.reloadLoading = false;
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>

<style >
.videoDirectorConfigForm table {
  table-layout: auto;
  border-collapse: collapse;
  width: 100%;
}
.videoDirectorConfigForm td.fitContent {
  width: 1%;
  white-space: nowrap;
}
.videoDirectorConfigForm .positionAbsoluteInPortlet {
  position: absolute;
  top: 15px;
  right: 15px;
}
</style>
